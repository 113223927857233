@media screen and (min-width: 1400px) and (max-width: 1430px) {
    .total-wrapper{ grid-template-columns: auto 189.23px 189.23px;
        &.type-2{ grid-template-columns: auto 175px;}
        &.type-3{ grid-template-columns: auto 240.43px; }
    }
}
@media screen and (max-width: 1400px) {
    .total-wrapper{ grid-template-columns: auto 175px 175px;
        // &.type-2{ grid-template-columns: auto 208.53px !important; }
        &.type-3{ grid-template-columns: auto 206.04px !important; }
    }
}

@media screen and (max-width: 1199px) {
    .desktop{ display: none; }
    .mobile { display: block; }
    .total-wrapper{ grid-template-columns: auto 140px 140px;
        &.type-2{ grid-template-columns: auto 140px !important; }
        &.type-3{ grid-template-columns: auto 162.11px !important; }
    }
    .main-menu .menu-right{ flex-direction: row-reverse; width: 100%; }
    .patch-icons-menu-inner{ margin: auto; }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .container.patch{ max-width: 1139px; }
}

@media screen and (max-width: 992px) {
    .total-wrapper{
        &.type-3{ grid-template-columns: auto 140px !important; }
    }
}

@media screen and (max-width: 767px) {
    .product-m{
        .owl-next{ display: none !important;}
        .owl-prev{ display: none !important;}
    }
    .product-box.type-2{
        .img-wrapper .lable-block .lable3 { 
            font-size: 1.3rem;
        }
    }
    .total-wrapper{ display: grid; grid-template-columns: 100%; justify-items: center; align-items: center;
        :first-child{ margin-left: 0; margin-right: 0; }
    }
}
@media screen and (max-width: 767px) and (min-width: 421px) {
    .product-m{
        .product-box{ max-width: 140px; }
    }
}
@media screen and (max-width: 577px) {
    .icon-nav ul:not(.btn-header) { position: fixed; display: flex; justify-content: space-between; right: 0; bottom: 0; gap: 1rem; width: 100%; padding: 0 1rem;
        li{ position: relative !important; left: 0; right: 0; 
            .desktop{ display: none !important; }
            .mobile{ display: block !important; height: 20px; }
        }
        .btn-header{ margin-top: 0; padding: 0; }
        .onhover-dropdown:hover .onhover-show-div{ transform: translateY(80%); -webkit-transform: translateY(80%); -moz-transform: translateY(80%); -ms-transform: translateY(80%); -o-transform: translateY(80%); }
    }
    .product-box .img-wrapper .lable-block .lable3{ padding: 7px 6px; }
    .footer-theme{
        .footer-contant, .col{ display: flex; justify-content: center; align-items: center; }
    }

    .blackout-shopping-widget{ display: none !important; }
    .cart-button{ display: none; }
    .shopping-widget{ display: none; }
    .shoppingCartCount{
        .count-inner{ font-size: .5rem; }
    }
    .filter{
        &.small{ min-width: 100%; }
    }
}
// @media screen and (min-width: 421px) {
//     .product-m{
//         .product-box{ max-width: 140px; margin: auto; }
//     }
// }
@media screen and (max-width: 420px) {
    .custom-product{
        .product-detail{ 
            .sumary{ height: auto; }
        }
    }
    .product-m{
        .product-box{ max-width: 240px; margin: auto; }
    }
}
