@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Poppins', sans-serif;;
    src: url('../../fonts/Gotham\ Book\ Font.otf');
  }
/*==============  products  ===============*/
*{ font-family: 'Poppins', sans-serif; }
.product-container-wrapper{ max-width: 1050px; width: 100%; margin: auto; padding: 0 2rem;
    &.type-2{ max-width: 900px; 
        span:not(.lable3){ font-size: 1rem !important; line-height: 1.3rem; }
        p{ font-size: 1rem !important; line-height: 1.3rem;
            &.small{ font-size: 0.913rem !important; line-height: 1.2rem; }
        }
        .product-container{ gap: 2rem; }
    }
    &.type-header{ background-color: #fff; max-width: calc(900px - 4rem); margin-bottom: 1.3rem;  display: flex; gap: .5rem; padding: .5rem 1rem; justify-content: space-between; align-items: center;
        &.max-width{ max-width: 900px; margin-bottom: 0; }
     }
    .product-container{ display: grid; grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); gap: 3rem; 
        &.fit{ grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); }
    }
    .section-divider{ display: flex; justify-content: space-between; gap: 1rem; flex-wrap: wrap; align-items: center; margin-bottom: 1rem;
        .divider-inner{ display: grid; grid-template-columns: 38px auto; align-items: center; gap: .7rem;
            h3{ margin-bottom: 0; color: #000; margin-top: .6rem;}
            img{ max-width: 100%; }
        }
        a:not(.not-link){ color: var(--theme-deafult) !important; font-size: 1rem; line-height: 1rem; margin-top: .7rem; text-decoration: underline; }
    }
}
.product-box.type-2{
    .img-wrapper .lable-block .lable3 { 
        font-size: 1.5rem; padding: 1px 10px;
    }
}
.product-m{
    .product-box{ max-width: 179.6px; }
}
.custom-product{
    &.carousel .product-detail{
        .sumary{ height: 60px; overflow: hidden; text-overflow: ellipsis; white-space: normal; display: -webkit-box !important; -webkit-line-clamp: 3; -webkit-box-orient: vertical; }
        span{ font-size: .9rem; font-weight: 400; line-height: 1.4rem; color: #000; }
        p{ display: block; margin-bottom: 0; font-size: .9rem; color: #000; font-weight: 400; line-height: 1.2rem;
            &.small{ font-size: 0.813rem; color: #999999; line-height: 1.3rem; }
        }
    }
    .img-wrapper{ position: relative; cursor: pointer;
        .love-it{ display: grid; justify-items: center; align-items: center; z-index: 1; width: 30px; height: 30px; position: absolute; top: 3%; right: 1%; background-color: #fff; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; 
            &.active{ svg path{ fill: var(--theme-deafult); }  }
            img, svg{ max-height: 18px; 
                &.fill{ animation: bounceIn .7s ease-in; -webkit-animation: bounceIn .7s ease-in; }
            }
        }
    }
    .grid-product{ display: grid; grid-template-columns: 120px auto; 
        .product-detail {
            p{ font-size: .9rem; text-overflow:ellipsis; overflow:hidden; display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; white-space: normal; 
                &.small{ font-size: 0.713rem; line-height: 1.2rem; }
            }
            span{ font-size: .9rem; }
            .sumary{ height: auto; }
        }
    }
    img{ width: 100%;}
    .product-detail{ margin: .7rem 0;
        .prices{ display: flex; gap: 0 .5rem; flex-wrap: wrap;
            .muted-color{ color: #999999; }
            .tached{ position: relative;
                &::after{ content: ''; position: absolute; top: 0; bottom: 0; margin: auto; left: 0; width: 100%; height: 1px; background-color: #999999; }
            }
        }
        .sumary{ height: 68px; overflow: hidden; text-overflow: ellipsis; white-space: normal; display: -webkit-box !important; -webkit-line-clamp: 3; -webkit-box-orient: vertical; }
        span{ font-size: 1.125rem; font-weight: 400; line-height: 1.4rem; color: #000; }
        p{ display: block; margin-bottom: 0; font-size: 1.125rem; color: #000; font-weight: 400; line-height: 1.4rem;
            &.small{ font-size: 0.913rem; color: #999999; line-height: 1.3rem; }
        }
    }
}

.category-list{
    &.overflow{ max-height: 610px; overflow: auto; padding-right: .5rem;
        &::-webkit-scrollbar-track { border-radius: 5px; background-color: #F5F5F5; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; }  
        &::-webkit-scrollbar { width: 5px; background-color: #F1F1F1; }
        &::-webkit-scrollbar-thumb { border-radius: 5px; background-color: #C1C1C1; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; 
            &:hover{ background-color: #A8A8A8; }
        }

    }
    li{ justify-content: center; cursor: pointer; padding: .5rem; background-color: #EDEFF473; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; margin-bottom: 1rem;
        &:hover, &.active {
            p{ color: #001689; }
            svg{
                path.change, path.change.color2, path.change.color3{ fill: #001689; }
                path.change.color4, path.change.color5{ fill: #101947; }
            }
            img{ filter: none; -webkit-filter: none; }
        }
        img{ max-width: 60%; max-height: 100px;  filter: grayscale(100%) opacity(.4); -webkit-filter: grayscale(100%) opacity(.4); transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in; }
        p{ margin: .2rem; color: #000; font-size: 1rem; transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in; }
    }
    svg { margin: auto;
        path.change{ fill: #929497; transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in;
            &.color2{ fill: #999A9C; }
            &.color3{ fill: #C5C5C6; }
            &.color4{ fill: url(#paint0_radial_2179_39229); }
            &.color5{ fill: #F5F6F6; }
        }
    }
}
.theme-card{
    .ti-angle-left{ display: none; }
    .ti-angle-right{ display: none; }
}

.grid-collections{ display: grid; grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); gap: 1rem; margin: 0 1.5rem;
}
.collection-item{ width: 100%; height: 200px; padding: 1.8rem 1rem 1rem;
    a{ width: 100%; height: 100%;
        .collection-banner{ width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; gap: 1rem; }
    }
    img{ height: 80px; width: auto !important; }
    h3{ color: #000; font-size: 23px;
        &.clear{ color: #ebebeb; }
    }
}
.table-container:not(:last-child){ margin-bottom: 1.2rem; }
.table-container{
    table tbody td:first-child{ width: 443px; }
} 
table{
    h3{ text-transform: capitalize !important; }
    thead{ border-bottom: 1px solid var(--theme-deafult); 
        th{ font-weight: 600; font-size: 1.1rem; text-align: center; padding-top: 1rem !important; padding-bottom: 1rem !important; }
    }
    tbody{ border-top: 0 !important;
        &:not(:last-child){ border-bottom: 1px solid #f3f3f3; }
        .icon img{ width: 16px; height: 16px; margin: 0 1rem;}
    }
    td, th{ border: 0 !important; }
    .grid-item{ display: grid; grid-template-columns: 55px auto; align-items: center; gap: .5rem; text-align: left; min-width: 200px;
        h5{ margin-bottom: 0 !important; }
        img{ height: 60px !important; }
    }
}
.product-m{ display: flex; justify-content: center;
    .owl-next{ background: var(--theme-deafult) !important; opacity: 1; font-size: 1.5rem !important; right: -89px; height: 38px !important; width: 34px !important; padding: 7px 6px !important; transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in;
        &:hover{ opacity: 1; }
    }
    .owl-prev{ background: var(--theme-deafult) !important; opacity: 1; font-size: 1.5rem !important; left: -89px; height: 38px !important; width: 34px !important; padding: 7px 6px !important; transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in;
        &:hover{ opacity: 1; }
    }
}
.total-wrapper{ display: grid; grid-template-columns: auto 210px 210px; justify-items: end; align-items: center;
    &.type-2{ grid-template-columns: auto 210px; }
    &.type-3{ grid-template-columns: auto 255.09px; }
    :first-child{ margin-left: auto; }
}
.relative{ position: relative; }

/*==============  Modal  ===============*/
.cdk-overlay-pane{ width: 100%; }
.mat-mdc-dialog-content{ max-height: 80vh !important; }
.modal-inner{
    &.type-2{ 
        .card-default{
             height: 80vw; }
        }
    h4{ color: #000; }
}

/*==============  iframe  ===============*/
iframe{ width: 100%; min-height: 733px; }

 /*==============  location-page  ===============*/
 .location-page{ position: relative; min-height: calc(100vh - 131px);
    img:not(.icon){ height: calc(100vh - 80px); width: 100%; object-fit: cover; }
    .absolute-section{ position: absolute; inset: 0 0 0 0; margin: auto; z-index: 1; height: fit-content; max-width: 850px; width: 100%; padding: 1rem;
        .select-container{ position: relative;
            .icon{ position: absolute; top: 0; bottom: 0; left: 1.5rem; margin: auto; }
            select{ padding: 1.1rem 1.1rem 1.1rem 4.5rem; background-color: #FFFFFF1A; color: #fff; width: 100%; height: fit-content; font-size: 1rem; border: 0; border-radius: 0.375rem; -webkit-border-radius: 0.375rem; -moz-border-radius: 0.375rem; -ms-border-radius: 0.375rem; -o-border-radius: 0.375rem;
                option{ background: #2e2e2e; }
            }
        }
    }
    .btn{ max-height: 54px; }
}
/*==============  forms  ===============*/
.form-control{ height: 40px; }
.qty-box .input-group { flex-wrap: nowrap;
    button{ padding: 0; display: inline-block; 
        i{ vertical-align: middle; margin: 0 .7rem; }
    }
}
.input-container{ position: relative;
    &.control-invalid{
        input, select, textarea { border: 1px solid var(--theme-deafult); }
        span{ color: var(--theme-deafult); }
    }
    &.password{ 
        .hide-wrapper{ position: absolute; top: 15px; right: 10px; width: 20px; cursor: pointer; height: fit-content; 
            &.hide::after{ content: ''; margin: auto; background-color: #fff; border-top: 2px solid #777777; width: 120%; height: 3px; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; position: absolute; top: 10%; bottom: 0; left: -8%; transform: rotateZ(-45deg); -webkit-transform: rotateZ(-45deg); -moz-transform: rotateZ(-45deg); -ms-transform: rotateZ(-45deg); -o-transform: rotateZ(-45deg); animation: fadeIn .2s ease-in; -webkit-animation: fadeIn .2s ease-in; }
            img{ max-width: 100%; }
        }
        input{ padding: 16px 35px 12px 7px; }
    }
    &.with-button{ 
        input{ padding-right: 4.5rem; }
        .btn-inner{ font-size: .8rem; padding: 0rem 0.7rem; position: absolute; right: .5rem; top: 0; bottom: 0; margin: auto; max-height: 30px; border: 1px solid #777777; background-color: #777777; color: #fff; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; transition: all .3s ease-in; -webkit-transition: all .3s ease-in; -moz-transition: all .3s ease-in; -ms-transition: all .3s ease-in; -o-transition: all .3s ease-in; 
            &:hover{ background-color: var(--theme-deafult); border: 1px solid var(--theme-deafult); }
        }
    } 
    input, select, textarea { padding: 16px 7px 12px; font-size: 16px; line-height: 20px; width: 100%; border: 1px solid #DDDDDD; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in;
        &::placeholder{ opacity: .4; font-size: .95rem; }
    }
    input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {  -webkit-appearance: none;  margin: 0; }
    input[type=number] { -moz-appearance:textfield; }
    span{ position: absolute; top: -0.6rem; left: 0.8rem; color: #777777; background-color: #fff; padding: 0 .5rem; transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in; }
    p{ margin-bottom: 0; color: var(--theme-deafult) !important; font-size: .7rem; position: absolute; line-height: 1rem;
        img{ width: 13px; margin-right: .3rem; margin-top: -1px; }
    }
 }
/*==============  Toast  ===============*/
.toast-container{ position: fixed; bottom: auto; top: 141px; display: flex; flex-direction: column; gap: .5rem;
    .toast-error{ background-color: var(--theme-deafult) !important; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; }
    .toast-success{ background-color: #007934 !important; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; }
    .toast-info{ border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; transition: all .3s ease-out; -webkit-transition: all .3s ease-out; -moz-transition: all .3s ease-out; -ms-transition: all .3s ease-out; -o-transition: all .3s ease-out; }
}

/*==============  contact us page  ===============*/
.contact-us{ 
    p{ margin-bottom: 0; text-align: center; color: #000; }
    h3:not(.notFound){ text-transform: uppercase; font-size: 16px; color: #444444; font-weight: 600; margin-bottom: 1rem; }
 }
.map-container{ position: relative; width: 100%; height: 427px;
    &.full{ height: 100%; min-height: 427px; }
    .absolute-map{ position: absolute; z-index: 1; background: none rgb(255, 255, 255);  border: 0px; margin: 10px; padding: 0px; text-transform: none; appearance: none; position: absolute; cursor: pointer; user-select: none; border-radius: 2px; height: 40px; width: calc( 100% - 20px ); max-width: 400px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; top: 0px; left: 0px; padding: 10px;}
}
.hoverMapOption{ cursor: pointer; padding: .6rem 0; margin-bottom: .3rem;
    &:hover, &.active{ background-color: rgba(237, 239, 244, 0.4509803922); transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in; }
}

 /*==============  login  ===============*/
.login-container{ max-width: 430px; width: 100%; margin: auto; 
    &.sign-in{ max-width: 1000px; }
    h4{ font-size: 24px; font-weight: 500; line-height: 1.2; }
}
 a, p a.loginA{ color: var(--theme-deafult);
    &.greenA{ color: #007934; 
        &:hover{ color: darken(#007934, 5%); }
    }
    &:hover{ color: darken(#AD0F31, 5%); }
}

/*==============  general  ===============*/
.pointer{ cursor: pointer; }
img{ max-width: 100%; }
.w-fit{ width: fit-content !important; }
.text-normal{ text-transform: unset !important; }
.primary-color{ color: var(--theme-deafult) !important; }
.badge{
    &.bg-gray{ background-color: rgb(234 234 235 / 45%); color: #007934 ; font-weight: 400;}
}
ul.list li{ color: #777777; list-style: disc; display: revert; margin-left: 1rem; }
.grid-4{ display: grid; grid-template-columns: repeat(auto-fit, minmax(230px, 1fr)); }
.gap-y-2{ gap: .5rem 0; }
.text-green{ color: #007934 !important; }
.mb-43{ margin-bottom: 2rem !important; }
.mb-5{ margin-bottom: 4rem !important; }
.custom-badge{ margin-left: auto; background-color: var(--theme-deafult); line-height: 1; margin-bottom: 0; display: flex; align-items: center; padding: 5px 10px; color: #fff; font-weight: 400; font-size: 10px; border-radius: 2px; -webkit-border-radius: 2px; -moz-border-radius: 2px; -ms-border-radius: 2px; -o-border-radius: 2px; }
.separator{ position: relative; text-align: center; margin: 2.5rem 0;
    span{ color: #777777; padding: 0 .5rem; background-color: #fff; z-index: 2; position: relative; }
    &::after{ content: ''; z-index: 1; width: 100%; height: 1px; position: absolute; top: 0; bottom: 0; left: 0; margin: auto; background-color: #DDDDDD; }
}
.btn{ display: grid; font-weight: 500; padding: 1.1rem; border-radius: 0.313rem; -webkit-border-radius: 0.313rem; -moz-border-radius: 0.313rem; -ms-border-radius: 0.313rem; -o-border-radius: 0.313rem; 
    &.icon{ line-height: 1.5rem; width: 100%; display: flex; align-items: center; justify-content: space-evenly; line-height: 1.2rem; gap: .3rem; 
        img{ width: 1.2rem; }
    }
    &.btn-sm{ padding: .3rem .5rem !important; font-size: .75rem; }
    &.btn-xsm{ padding: 0 0.5rem !important; font-size: .65rem; height: 20.55px; line-height: 1.8; }
    &.btn-md{ padding: .8rem 1.1rem; }
}
.btn-icon-login{ cursor: pointer; background-color: rgba(#AD0F31, 0.05); padding: 10px; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px;
    &:hover{ background-color: rgba(#AD0F31, 0.1);; }
    img{ max-width: 30px; }
 }
.btn-primary-clearest{ background-color: rgba(#AD0F31, 0.05); border-color: rgba(#AD0F31, 0.05); border: 1px solid rgba(#AD0F31, 0.05); color: var(--theme-deafult); 
    &:hover{ background-color: rgba(#AD0F31, 0.1); border: 1px solid rgba(#AD0F31, 0.1); color: var(--theme-deafult);  }
    &:active{ background-color: rgba(#AD0F31, 0.1) !important; border: 1px solid rgba(#AD0F31, 0.1) !important; color: var(--theme-deafult) !important;  }
    &:focus-visible{ background-color: rgba(#AD0F31, 0.1) !important; border: 1px solid rgba(#AD0F31, 0.1) !important; color: var(--theme-deafult);  }
}
.btn-primary{ background-color: var(--theme-deafult); border-color: var(--theme-deafult); border: 1px solid var(--theme-deafult); 
    &:hover{ background-color: darken(#AD0F31, 5%); border: 1px solid darken(#AD0F31, 5%); }
    &:active{ background-color: darken(#AD0F31, 5%) !important; border: 1px solid darken(#AD0F31, 5%) !important; }
    &:focus-visible{ background-color: darken(#AD0F31, 5%) !important; border: 1px solid darken(#AD0F31, 5%) !important; }
}
.btn-outline-primary{ background-color: transparent; border-color: transparent; border: 1px solid transparent; color: var(--theme-deafult); border: 1px solid var(--theme-deafult);
    &:hover{ background-color: darken(#AD0F31, 5%); border: 1px solid darken(#AD0F31, 5%); color: #fff;}
    &:active{ background-color: darken(#AD0F31, 5%) !important; border: 1px solid darken(#AD0F31, 5%) !important;  color: #fff !important;}
    &:focus-visible{ background-color: darken(#AD0F31, 5%) !important; border: 1px solid darken(#AD0F31, 5%) !important; color: #fff }
}
.btn-primary-light{ background-color: #C5242A; border-color: #C5242A; border: 1px solid #C5242A; color: #fff;
    &:hover{ background-color: darken(#C5242A, 5%); border: 1px solid darken(#C5242A, 5%); color: #fff;}
    &:active{ background-color: darken(#C5242A, 5%) !important; border: 1px solid darken(#C5242A, 5%) !important; color: #fff !important; }
    &:focus-visible{ background-color: darken(#C5242A, 5%) !important; border: 1px solid darken(#C5242A, 5%) !important; color: #fff; }
}
.btn-gray{ background-color: #A4A4A4; border-color: #A4A4A4; border: 1px solid #A4A4A4; color: #fff;
    &:hover{ background-color: darken(#A4A4A4, 5%); border: 1px solid darken(#A4A4A4, 5%); color: #fff; }
    &:active{ background-color: darken(#A4A4A4, 5%) !important; border: 1px solid darken(#A4A4A4, 5%) !important; color: #fff; }
    &:focus-visible{ background-color: darken(#A4A4A4, 5%) !important; border: 1px solid darken(#A4A4A4, 5%) !important; color: #fff; }
}
.btn-black{ background-color: #222222; border-color: #222222; border: 1px solid #222222; color: #fff;
    &:hover{ background-color: darken(#222222, 5%); border: 1px solid darken(#222222, 5%); color: #fff; }
    &:active{ background-color: darken(#222222, 5%) !important; border: 1px solid darken(#222222, 5%) !important; color: #fff; }
    &:focus-visible{ background-color: darken(#222222, 5%) !important; border: 1px solid darken(#222222, 5%) !important; color: #fff; }
}
.btn-black{ background-color: #222222; border-color: #222222; border: 1px solid #222222; color: #fff;
    &:hover{ background-color: darken(#222222, 5%); border: 1px solid darken(#222222, 5%); color: #fff; }
    &:active{ background-color: darken(#222222, 5%) !important; border: 1px solid darken(#222222, 5%) !important; color: #fff; }
    &:focus-visible{ background-color: darken(#222222, 5%) !important; border: 1px solid darken(#222222, 5%) !important; color: #fff; }
}
.btn-white{ background-color: #fff; border-color: #fff; border: 1px solid #fff; color: #777777;
    &:hover{ background-color: #AD0F31; border: 1px solid #AD0F31; color: #fff; }
    &:active{ background-color: #AD0F31 !important; border: 1px solid #AD0F31 !important; color: #fff; }
    &:focus-visible{ background-color: #AD0F31 !important; border: 1px solid #AD0F31 !important; color: #fff; }
}
.pre-footer-container{ background-color: #FAFAFA; padding: 2rem 0;
    .pre-footer{ display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        img{ margin: auto; cursor: pointer; }
    }
}
.card-default{ background-color: #fff; border: 1px solid #dddddd; padding: 30px; overflow: auto;
    &.sticky{ position: sticky; position: -webkit-sticky; top: 85px; }
    &.list:not(:last-child){ margin-bottom: .5rem; }
    &.iframe-container{ padding: 15px; }
    &.min-p{ padding: 12px 20px; 
        .border-product{ padding: 10px 0; }
        .element-grid{ padding: .6rem; gap: .3rem; }
    }
    h3{ text-align: center; text-transform: uppercase; font-size: 1rem; color: #444444; font-weight: 600; margin-bottom: 1rem; }
}
.section-b-space{
    &.change-image{ position: relative;
        .collection-wrapper{ margin-top: 7%;}
        .changing{ position: absolute; top: 0; left: 0; width: 100%; padding: 0;
            .changing-image{ z-index: 2; width: 100%; position: relative;
                img{ width: 100%; }
            }
        }
        .product-container-wrapper{ z-index: 3; position: relative; background-color: #fff; padding-top: 30px; padding-bottom: 30px; border: 1px solid #dddddd; }
        .col-lg-3{ z-index: 3; position: relative;}
    }
    .changing{ 
        .changing-image{ z-index: -1;  position: absolute; transition: all .3s ease-in; -webkit-transition: all .3s ease-in; -moz-transition: all .3s ease-in; -ms-transition: all .3s ease-in; -o-transition: all .3s ease-in;
            img{ width: 100%; animation: fadeIn .3s ease-in forwards; -webkit-animation: fadeIn .3s ease-in forwards; }
            .logo-image{ position: absolute; inset: 0 0 0 0; margin: auto; max-height: 100px; }
        }
    }
    .product-container-wrapper, .col-lg-3, .collection-wrapper{ transition: all .3s ease-in; -webkit-transition: all .3s ease-in; -moz-transition: all .3s ease-in; -ms-transition: all .3s ease-in; -o-transition: all .3s ease-in; }
}

.filter-container{ display: flex; justify-content: center; align-items: center; gap: 1.7vw; flex-wrap: wrap;
}
.filter{ align-self: baseline; width: 5.5vw; max-width: 70px; min-width: 65px; opacity: .3; cursor: pointer; transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in; filter: opacity(.8); -webkit-filter: opacity(.8);
    &.active{ opacity: 1; filter:  opacity(1); -webkit-filter:  opacity(1); 
        .filter-inner{ filter: grayscale(0); -webkit-filter: grayscale(0); }
        img{ filter: grayscale(0); -webkit-filter: grayscale(0); }
    }
    &:hover:not(.active){ opacity: 1; 
        // img{ filter: grayscale(.7); -webkit-filter: grayscale(.7); }
    }
    &.small{  max-width: 100%; min-width: auto;
        img{ height: auto; padding: 0 .2rem .1rem; }
        .filter-image-wrapper{ margin-bottom: 0; }
        .filter-inner{ height: 38px; min-height: auto; }
     }
    .filter-inner{ bottom: 0; position: absolute; width: 100%; height: 5.5vw; max-height: 70px; min-height: 65px; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; filter: grayscale(1); -webkit-filter: grayscale(1); }
    .filter-image-wrapper{ position: relative; margin-bottom: .5rem; }
    img{ margin-bottom: .5rem; z-index: 1; position: relative; display: block; margin: auto; padding-bottom: .5rem; filter: grayscale(1); -webkit-filter: grayscale(1); }
    h4{ text-align: center; font-size: 100%; }
}
.header-select-container{ position: relative; height: fit-content; margin-top: 10px; background-color: #0079341A; border-radius: 0.313rem; -webkit-border-radius: 0.313rem; -moz-border-radius: 0.313rem; -ms-border-radius: 0.313rem; -o-border-radius: 0.313rem;
    &::after{ content: url(../../../assets/la-cascada/icons/expand_more.svg); position: absolute; right: 1rem; top: 0; bottom: 0; margin: auto; width: fit-content; height: fit-content; }
    img{ position: absolute; left: 1rem; top: 0; bottom: 0; margin: auto; }
    .header-select{ background-color: transparent; position: relative; z-index: 1; width: 100%; max-width: 13rem; padding: 0.8rem 2.5rem 0.8rem 4rem; cursor: pointer; color: #00662C; border: 0; -webkit-appearance: none; -moz-appearance: none; }
}
.onhover-dropdown:before{ content: url(../../../assets/la-cascada/icons/expand_more_black.svg); right: 14px; top: 0; bottom: 0; margin: auto; height: fit-content; }
.onhover-dropdown .onhover-show-div{ padding: 1rem 1.5rem; border: 1px solid #E5E6E8; }
.icon-nav ul { 
    li { vertical-align: text-top;
        .mobile{ display: none !important; }
        .desktop{ display: block !important; }
    }
}
.btn-search{ padding: 0.8rem; line-height: 19.4px !important; font-size: 14px; text-transform: capitalize; height: 46.6px; }
.btn-header{ text-transform: capitalize; padding: 0.8rem; line-height: 19.4px;
    &.login-list{ padding: 0;
        .btn-login{ display: flex; align-items: center; gap: 1rem; padding: .4rem 2rem .4rem 0; }
        img{ max-width: 40px; }
        li:not(.btn-login){ width: 100%; padding: .5rem 0;
            &:not(:last-child){ border-bottom: 1px solid #E5E6E8; }
            &:hover{ 
                a{ color: #6d6e6e;  }
            }
            a{ color: #000; }
        }
    }
}
.accordion-item{ margin-bottom: 20px; 
    .accordion-button::after{ background-color: var(--theme-deafult); width: 30px; height: 30px; background-image: url(../../../assets/la-cascada/icons/expand_more_white.svg); background-position: center; background-repeat: no-repeat; background-size: 1rem; }
    .accordion-button:not(.collapsed)::after{ background-image: url(../../../assets/la-cascada/icons/expand_more_white.svg); }
}
.element-grid { display: flex; gap: .5rem; flex-wrap: wrap; align-items: center; flex-direction: column;
    &.active{ background-color: #EC4B43; 
        h4{ color: #fff; }
        p{ color: #fff; }
    }
    &.actived{ flex-direction: row; width: 100%; justify-content: center;
        &::before{ content: ''; width: 25px; height: 25px; background-color: var(--theme-deafult); background-image: url(../../../assets/la-cascada/icons/check_white.svg); background-position: center; background-size: 1rem; background-repeat: no-repeat; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; }
    }
    &.f-row{ flex-direction: row; justify-content: center; 
        img{ height: 3rem !important; width: auto !important; }
        h4{ margin-top: 6px; }
    }
    &.about-icon{
        img{ max-width: 2.5rem; }
    } 
    p{ color: #000; line-height: 1.3rem; text-align: center; }
    h4{ text-align: center; }
}

.custom-radio{
    .title{ font-size: 1rem ; color: #000; text-transform: capitalize;}
    P{ font-size: .9rem; margin-bottom: .1rem; }
    label { display: flex; cursor: pointer; font-weight: 500; position: relative; overflow: hidden; margin-bottom: 0.375em;
        input { position: absolute; left: -9999px;
            &:checked + span { background-color: rgba(#AD0F31, 0.05); border-color: #AD0F31;
                &:before {
                    box-shadow: inset 0 0 0 0.4375em #AD0F31;
                }
            }
            & + span { border-style: solid; border-width: 1px; border-color: transparent; }
        }
        span { background-color: #EDEFF440; width: 100vw; max-width: 300px; display: grid; grid-template-columns: 40px auto; padding: .7rem; border-radius: 3px; transition: 0.25s ease; -webkit-border-radius: 3px; -moz-border-radius: 3px; -ms-border-radius: 3px; -o-border-radius: 3px;
            &:hover{ background-color: rgba(#AD0F31, 0.05); }
            &:before { display: flex; flex-shrink: 0; content: ""; background-color: #fff; width: 1.5em; height: 1.5em; border-radius: 50%; margin-right: 0.375em; transition: 0.25s ease; box-shadow: inset 0 0 0 0.125em #a2a2a2; }
        }
    }
}
.custom-radio-2{
    .title{ font-size: 1rem ; color: #000;}
    P{ font-size: .9rem; margin-bottom: .1rem; }
    label { display: flex; cursor: pointer; font-weight: 500; position: relative; overflow: hidden; margin-bottom: 0.375em;
        input { position: absolute; left: -9999px;
            &:checked + span {
                &:after {
                    box-shadow: inset 0 0 0 0.4375em #AD0F31;
                }
            }
            & + span { border-style: solid; border-width: 1px; border-color: transparent; }
        }
        span { width: 100%; background-color: rgba(237, 239, 244, 0.4509803922); display: grid; grid-template-columns: auto 40px; align-items: center; padding: .7rem; border-radius: 3px; transition: 0.25s ease; -webkit-border-radius: 3px; -moz-border-radius: 3px; -ms-border-radius: 3px; -o-border-radius: 3px;
            img{ max-width: 23px; object-fit: contain; }
            &:after { margin-left: auto; display: flex; flex-shrink: 0; content: ""; background-color: #fff; width: 1.5em; height: 1.5em; border-radius: 50%; transition: 0.25s ease; box-shadow: inset 0 0 0 0.125em #a2a2a2;  }
        }
    }
}

.custom-radio-3{
    P{ font-size: .9rem ; color: #777777; font-weight: 400; }
    label { display: flex; cursor: pointer; font-weight: 500; position: relative; overflow: hidden; margin-bottom: 0.375em;
        input { position: absolute; left: -9999px;
            &:checked + span {
                &:before {
                    box-shadow: inset 0 0 0 0.2375em #AD0F31;
                }
            }
            & + span { border-style: solid; border-width: 1px; border-color: transparent; }
        }
        span { width: 100%; display: grid; grid-template-columns: 20px auto; align-items: center; padding: .3rem .7rem; border-radius: 3px; transition: 0.25s ease; -webkit-border-radius: 3px; -moz-border-radius: 3px; -ms-border-radius: 3px; -o-border-radius: 3px;             
            &:before { display: flex; flex-shrink: 0; content: ""; background-color: #fff; width: 15px; height: 15px; border-radius: 50%; transition: 0.25s ease; box-shadow: inset 0 0 0 0.125em #DDDDDD;  }
        }
    }
}
.custom-check{
    P{ font-size: .9rem ; color: #777777; font-weight: 400; }
    label { display: flex; cursor: pointer; font-weight: 500; position: relative; overflow: hidden; margin-bottom: 0.375em;
        input { position: absolute; left: -9999px;
            &:checked + span {
                &:before {
                    background-color: var(--theme-deafult); border-color: var(--theme-deafult); background-image: url(../../../assets/la-cascada/icons/check_white.svg); background-position: center; background-size: contain;
                }
            }
            & + span { border-style: solid; border-width: 1px; border-color: transparent; }
        }
        span { width: 100%; display: grid; grid-template-columns: 20px auto; align-items: center; padding: .3rem .7rem; border-radius: 3px; transition: 0.25s ease; -webkit-border-radius: 3px; -moz-border-radius: 3px; -ms-border-radius: 3px; -o-border-radius: 3px;             
            &:before { display: flex; flex-shrink: 0; content: ''; font-weight: 900; background-color: #fff; width: 15px; height: 15px; transition: 0.25s ease; border-width: 2px; border-style: solid; border-color: #DDDDDD;; border-radius: 3px; -webkit-border-radius: 3px; -moz-border-radius: 3px; -ms-border-radius: 3px; -o-border-radius: 3px; }
        }
    }
}
.grid-directions{ display: grid; grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)); gap: .5rem; }
.header-select-container.mobile{
    select{ padding: 1.1rem 2.5rem 1.1rem 4rem; }
}
.shoppingCartCount{ position: relative;  
    .count-inner{ background-color: var(--theme-deafult); color: #fff; position: absolute; right: -11px; top: -12px; width: fit-content; height: fit-content; font-size: 70%; line-height: 1; border-radius: 50%; width: 15px; height: 15px; display: flex; justify-content: center; align-items: center; }
}
.mobile{ display: none;}
.btn-border{ background-color: #424B55; opacity: 0; height: 56.95px; display: flex; margin-top: .5rem; justify-content: space-evenly; width: 100%; border-radius: 0.313rem; -webkit-border-radius: 0.313rem; -moz-border-radius: 0.313rem; -ms-border-radius: 0.313rem; -o-border-radius: 0.313rem; animation: fadeIn .2s ease-in forwards; -webkit-animation: fadeIn .2s ease-in forwards;
    .qty-box.minified{ height: fit-content; margin: auto 0;
        .input-group{  display: flex; justify-content: center; align-items: center;
            button { display: flex; justify-content: center; align-items: center;
                &.quantity-left-minus{ border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; }
                &.quantity-right-plus{ border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; }
                i{ margin: .5rem; font-size: .6rem; font-weight: 900; color: #424B55;}
            }
            input{ max-width: 32px; padding: 0; font-size: .9rem; height: 30px; background-color: transparent; font-weight: 600; color: #fff; border: none; }
            span.paq{ color: #fff; padding: 0 7px 0 0; font-size: .8rem; }
        }
    }
    .icons-quantity{ display: flex; justify-content: center; align-items: center; gap: .2rem; width: auto; flex-direction: column;
        i{ font-weight: 900; cursor: pointer; font-size: 10.5px; transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in; }
        :first-child{ color: #007934; background-color: #E5F1EA; border: 1px solid #007934; width: 28px; height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 0.313rem; -webkit-border-radius: 0.313rem; -moz-border-radius: 0.313rem; -ms-border-radius: 0.313rem; -o-border-radius: 0.313rem; 
            &:hover{ background-color: #007934; color: #fff; }
        }
        :last-child{ color: var(--theme-deafult); background-color: #FBF3F4; border: 1px solid var(--theme-deafult); width: 28px; height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 0.313rem; -webkit-border-radius: 0.313rem; -moz-border-radius: 0.313rem; -ms-border-radius: 0.313rem; -o-border-radius: 0.313rem; }
    }
}
.blackout-shopping-widget{ display: none; position: fixed; width: 100%; height: 100%; background-color: rgba(0,0,0,.5); z-index: 999; animation: fadeOut .2s forwards; -webkit-animation: fadeOut .2s forwards;
    &.visual-active{ animation: fadeIn .2s forwards; -webkit-animation: fadeIn .2s forwards; } 
    &.active{ display: block; } 
}
.blackout-mobile-menu{ display: none; position: fixed; width: 100%; height: 100%; background-color: rgba(0,0,0,.5); z-index: 9999; animation: fadeOut .2s forwards; -webkit-animation: fadeOut .2s forwards; top: 0; left: 0;
    &.visual-active{ animation: fadeIn .2s forwards; -webkit-animation: fadeIn .2s forwards; } 
    &.active{ display: block; } 
}
.cart-button{ cursor: pointer; width: 60px; height: 60px; position: fixed; bottom: 2rem; right: 2rem; background-color: #fff; z-index: 99999; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; box-shadow: 0 4px 30px rgba(0,0,0,.17); transition: all .3s cubic-bezier(.33,.55,.29,1.07); -webkit-transition: all .3s cubic-bezier(.33,.55,.29,1.07); -moz-transition: all .3s cubic-bezier(.33,.55,.29,1.07); -ms-transition: all .3s cubic-bezier(.33,.55,.29,1.07); -o-transition: all .3s cubic-bezier(.33,.55,.29,1.07);
    &.active{ box-shadow: none; transform: rotate(90deg); -webkit-transform: rotate(90deg); -moz-transform: rotate(90deg); -ms-transform: rotate(90deg); -o-transform: rotate(90deg); 
        img{
            &.close{ opacity: 1; }
            &.cart{ opacity: 0; }
        }
        .cart-widget-count{ animation: scaleWidgetCount .01s forwards; -webkit-animation: scaleWidgetCount .01s forwards; }
    }
    img{ width: 20px; position: absolute; inset: 0 0 0 0; margin: auto;
        &.close{ opacity: 0; }
        &.cart{ opacity: 1; }
    }
    .cart-widget-count{ position: absolute; top: -10px; left: -10px; background-color: var(--theme-deafult); width: 25px; height: 25px; display: flex; justify-content: center; align-items: center; color: #fff; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; animation: scaleWidgetCountInv .3s forwards; -webkit-animation: scaleWidgetCountInv .3s forwards; }
}
.shopping-widget{ width: 100vw; max-width: 441px; position: fixed; bottom: 2rem; right: 2rem; z-index: 9999; background-color: #fff; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; transition: all .3s cubic-bezier(.33,.55,.29,1.07); -webkit-transition: all .3s cubic-bezier(.33,.55,.29,1.07); -moz-transition: all .3s cubic-bezier(.33,.55,.29,1.07); -ms-transition: all .3s cubic-bezier(.33,.55,.29,1.07); -o-transition: all .3s cubic-bezier(.33,.55,.29,1.07); 
    &.hide{ transform: translate(43%, 43%) scale(.05); -webkit-transform: translate(43%, 43%) scale(.05); -moz-transform: translate(43%, 43%) scale(.05); -ms-transform: translate(43%, 43%) scale(.05); -o-transform: translate(43%, 43%) scale(.05); }
    .title-border{ padding: .9rem; margin: 0; line-height: 15px; font-size: 15px; }
    .content-border{ overflow: auto; max-height: 50vh;
        .content-product-wrapper{ padding: .5rem 1rem; border-top: 1px solid #dddddd; border-bottom: 1px solid #dddddd;
            .grid-item{ grid-template-columns: 16% 54% 30%; display: grid; gap: 1rem; width: calc( 100% - 2rem );
                .ti-angle-left{ display: block; }
                .ti-angle-right{ display: block; }
                .media-body{
                    .qty-box{
                        .input-group{ justify-content: start; 
                            button {
                                &.quantity-right-plus{ border-top-left-radius: 0; border-bottom-left-radius: 0; }
                                &.quantity-left-minus{ border-top-right-radius: 0; border-bottom-right-radius: 0; }
                                i{ font-size: 8px; }
                            }
                        }
                        input{ height: 27px; width: 47px; font-size: .8rem; }
                    }
                }
                h5{ padding: 0; font-size: .85rem; line-height: 1rem; text-transform: none;
                    &.price{ width: fit-content; margin-left: auto; }
                    &.muted-color{ color: #999999; }
                    &.tached{ position: relative;
                        &::after{ content: ''; position: absolute; top: 0; bottom: 0; margin: auto; left: 0; width: 100%; height: 1px; background-color: #999999; }
                    }
                }
                .icon{ margin-left: auto; display: block; width: fit-content; margin-top: auto; padding: 0.375rem 0;
                    img{ height: 18px; }
                }
            }
        }
        .product-m {
            .owl-prev{ left: -10px; }
            .owl-next{ right: -10px; }
            .product-box{ margin: .5rem; max-width: 100%; border: 1px solid #dddddd; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px;
                .custom-product .grid-product{ grid-template-columns: 90px auto; }
            }
        } 
        .section-b-space{ padding: 0; }
        .section-divider{ margin-bottom: 0;
            p{font-size: 15px; color: #777777; line-height: 24px; letter-spacing: 0.03em; }
        }
    }
    .bottom-border{ cursor: pointer; background-color: var(--theme-deafult); margin-bottom: 0; display: grid; grid-template-columns: auto 60px; height: 60px; width: 100%; align-items: center; justify-items: center; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; transition: 0.3s ease-in-out; -webkit-transition: 0.3s ease-in-out; -moz-transition: 0.3s ease-in-out; -ms-transition: 0.3s ease-in-out; -o-transition: 0.3s ease-in-out;
        &:hover{ background-color: darken(#AD0F31, 5%); 
            img{ opacity: 1; transform: translateX(30%); -webkit-transform: translateX(30%); -moz-transform: translateX(30%); -ms-transform: translateX(30%); -o-transform: translateX(30%); }
        }
        h5{ margin-bottom: 0; padding-bottom: 0; color: #fff; text-transform: none; }
        img{ margin-left: .2rem; opacity: .5; transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in; }
    }
}
.cart-section{
    &.terms{ 
        img{ width: 24px; height: 25px; }
    }
}
.h-fit{ height: fit-content !important; }
.box-detail{ max-width: 800px; margin: auto; 
    p{ text-align: left; }
}
/*==============  sweet alert  ===============*/
.swal2-actions{ flex-direction: row-reverse; }
div:where(.swal2-container) button:where(.swal2-styled){
    &.swal2-confirm{ background-color: var(--theme-deafult) !important; 
        &:focus{ box-shadow: 0 0 0 3px rgba(173, 15, 49, 0.2) !important; }
    }
    &.swal2-cancel{ background-color: #222222 !important; }
}
.grid-detail{ margin-top: 1rem; display: grid; grid-template-columns: 48% 4% 48%; gap: .5rem; text-align: left; line-height: 1.2; width: calc(100% - 1rem); overflow: hidden;}


/*==============  loader  ===============*/
.loader-full{ display: none; position: fixed; width: 100vw; height: 100vh; background-color: #fff; z-index: 999999; justify-content: center; align-items: center;
    &.show{ display: flex; }
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--theme-deafult);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  

/*==============  animations  ===============*/
@keyframes fadeIn {
    0%{ opacity: 0; }
    100%{ opacity: 1; }
}

@keyframes fadeOut {
    0%{ opacity: 1; }
    100%{ opacity: 0; }
}

@-webkit-keyframes bounceIn {
    0%, 20%, 40%, 60%, 80%, 100% {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
  
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.3, .3, .3);
      transform: scale3d(.3, .3, .3);
    }
  
    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
  
    40% {
      -webkit-transform: scale3d(.9, .9, .9);
      transform: scale3d(.9, .9, .9);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }
  
    80% {
      -webkit-transform: scale3d(.97, .97, .97);
      transform: scale3d(.97, .97, .97);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
}
@keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  @keyframes scaleWidgetCount {
    0%{ transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
    100%{ transform: scale(0); -webkit-transform: scale(0); -moz-transform: scale(0); -ms-transform: scale(0); -o-transform: scale(0); }
  }
  @keyframes scaleWidgetCountInv {
    0%{ transform: scale(0); -webkit-transform: scale(0); -moz-transform: scale(0); -ms-transform: scale(0); -o-transform: scale(0); }
    60%{ transform: scale(0); -webkit-transform: scale(0); -moz-transform: scale(0); -ms-transform: scale(0); -o-transform: scale(0); }
    100%{ transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
  }
